import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JankariService } from 'src/app/shared/services/jankari.service';

@Component({
  selector: 'app-hardwareotherdetailpopup',
  templateUrl: './hardwareotherdetailpopup.component.html',
  styleUrls: ['./hardwareotherdetailpopup.component.scss'],
})
export class HardwareotherdetailpopupComponent implements OnInit {
  centid;
  centerhardwarecalldetails: any;
  count: any;
  tlname: any;
  tlmobno: any;
  pcname: any;
  pcno: any;
  paname: any;
  pano: any;
  ohname: any;
  ohno: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public jankariservice: JankariService
  ) {
    this.centid = data.id;
    console.log('popup_centid=' + this.centid);

    this.jankariservice
      .GetCenterHardwareCallDetail(this.centid)
      .subscribe((data: any) => {
        this.centerhardwarecalldetails = data;
        this.tlname = data.TLName;
        this.tlmobno = data.TLmobileno;
        this.pcname = data.PCName;
        this.pcno = data.PCmobileno;
        this.paname = data.ProgassociateName;
        this.pano = data.ProgAssmobileno;
        this.ohname = data.OHName;
        this.ohno = data.OHmobileno;
      });
  }

  ngOnInit() {}

  clear() {
    this.dialog.ngOnDestroy();
  }
}
