import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MatDialogModule, MatBadgeModule, MatFormFieldModule, MatButtonModule, MatInputModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { DialogpopupComponent } from './layout/Employee/dialogpopup/dialogpopup.component';
import { SalarybrekuppopupComponent } from './layout/Employee/salarybrekuppopup/salarybrekuppopup.component';
import { SalaryincrementpopupComponent } from './layout/Employee/salaryincrementpopup/salaryincrementpopup.component';
import { ForgotpasswordpopupComponent } from './layout/Employee/forgotpasswordpopup/forgotpasswordpopup.component';
import { OtppopupComponent } from './layout/Employee/otppopup/otppopup.component';
import { AuthGuard } from './shared/guard';
import { HardwareotherdetailpopupComponent } from './layout/center/Hardwarecall/hardwareotherdetailpopup/hardwareotherdetailpopup.component';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
  declarations: [
    AppComponent,
    DialogpopupComponent,
    SalarybrekuppopupComponent,
    SalaryincrementpopupComponent,
    ForgotpasswordpopupComponent,
    OtppopupComponent,
    HardwareotherdetailpopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    OverlayModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  entryComponents: [
    DialogpopupComponent,
    SalarybrekuppopupComponent,
    SalaryincrementpopupComponent,
    ForgotpasswordpopupComponent,
    OtppopupComponent,
    HardwareotherdetailpopupComponent,
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
