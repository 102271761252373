import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Forgetpassword } from '../../../../app/classfiles/forgetpassword';
import { AdoreserviceService } from 'src/app/shared/services/adoreservice.service';

@Component({
  selector: 'app-otppopup',
  templateUrl: './otppopup.component.html',
  styleUrls: ['./otppopup.component.scss'],
})
export class OtppopupComponent implements OnInit {
  otp: string = '';
  otpuserId: any;

  submited = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private service: AdoreserviceService,
    private toastr: ToastrService
  ) {
    this.otpuserId = localStorage.getItem('otpdata');
  }

  ngOnInit() {}

  Submit() {
    // console.log(this.otp)

    const data: Forgetpassword = new Forgetpassword();

    data.username = this.otpuserId;
    data.dummypass = this.otp;

    this.service.CheckOTP(data).subscribe(
      (res) => {
        this.submited.emit(res.toString());

        //  console.log(res.toString());
        if (res.toString() == 'Success') {
          localStorage.setItem('otp', this.otp);
          this.toastr.success('OTP Verify Successfully');
          this.dialog.closeAll();
          this.router.navigate(['/change-password']);
        } else {
          this.toastr.error('Enter Proper OTP');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
