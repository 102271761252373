import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'prefix' },
  {
    path: 'Privacy', loadChildren: () =>
      import('./privacypolicy/privacypolicy.module').then((x) => x.PrivacypolicyModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then((x) => x.LayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((x) => x.LoginModule),
  },
  {
    path: 'downloadsalaryslip/:id/:id1/:id2',
    loadChildren: () =>
      import('./salary-slip-download/salary-slip-download.module').then(
        (x) => x.SalarySlipDownloadModule
      ),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./change-password/change-password.module').then(
        (x) => x.ChangePasswordModule
      ),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./server-error/server-error.module').then(
        (x) => x.ServerErrorModule
      ),
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./not-found/not-found.module').then((x) => x.NotFoundModule),
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
