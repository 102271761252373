import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class JankariService {
  constructor(private http: HttpClient) {}

  FillDropDown(tablename: any, column1: any, column2: any, where: any) {
    return this.http.get(
      environment.jankariUrl +
        'JankariPortal/' +
        'FillDropDown?tablename=' +
        tablename +
        '&column1=' +
        btoa(column1) +
        '&column2=' +
        btoa(column2) +
        '&whr=' +
        btoa(where)
    );
  }

  GetHardwarecallAlert(userid: string, role: string, pgid: string, where: any) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'GetHardwarecallAlert/' +
        userid +
        '/' +
        role +
        '/' +
        pgid +
        '?whr=' +
        btoa(where)
    );
  }

  AddHardwareCallProblem(callid: string, data: any) {
    return this.http.post(
      environment.jankariUrl + 'Center/' + 'AddHardwareCallProblem/' + callid,
      data
    );
  }

  GetHardwareCallProblem(callid: string): any {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetHardwareCallProblem/' + callid
    );
  }

  RemoveHardwareCallProblem(hid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveHardwareCallProblem/' + hid
    );
  }

  AddHardwareCallVisit(callid: string, data: any) {
    return this.http.post(
      environment.jankariUrl + 'Center/' + 'AddHardwareCallVisit/' + callid,
      data
    );
  }

  GetHardwareCallVisit(callid: string): any {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetHardwareCallVisit/' + callid
    );
  }

  RemoveHardwareCallVisit(hvid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveHardwareCallVisit/' + hvid
    );
  }

  AddHardwareCallMaterial(callid: string, data: any) {
    return this.http.post(
      environment.jankariUrl + 'Center/' + 'AddHardwareCallMaterial/' + callid,
      data
    );
  }

  GetHardwareCallMaterial(callid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetHardwareCallMaterial/' + callid
    );
  }

  RemoveHardwareCallMaterial(hvid: string, rid: number) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'RemoveHardwareCallMaterial/' +
        hvid +
        '?rid=' +
        rid
    );
  }

  UpdateHardwareCall(callid: string, userid: string, data: any) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'UpdateHardwareCall/' +
        callid +
        '/' +
        userid,
      data
    );
  }

  GetCenterHardwareCallDetail(centid: string) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'GetCenterHardwareCallDetail/' +
        centid
    );
  }

  GetHardwareCallDetail(userid: string, callid: string) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'GetHardwareCallDetail/' +
        userid +
        '/' +
        callid
    );
  }

  GetCenterDetails(centid: string): any {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetCenterDetails/' + centid
    );
  }

  GetHardwarecallPendingAlertAD(userid: string, role: string, where: any): any {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'GetHardwarecallPendingAlertAD/' +
        userid +
        '/' +
        role +
        '?whr=' +
        btoa(where)
    );
  }

  GetHardwareCallListAD(userid: string, role: string, whr: any): any {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'GetHardwareCallListAD/' +
        userid +
        '/' +
        role +
        '?whr=' +
        btoa(whr)
    );
  }

  GetName(table: string, col: string, where: any) {
    return this.http.get(
      environment.jankariUrl +
        'JankariPortal/' +
        'GetName?table=' +
        table +
        '&col=' +
        btoa(col) +
        '&whr=' +
        btoa(where)
    );
  }
}
