import { Component, OnInit, Inject } from '@angular/core';
import { AdoreserviceService } from 'src/app/shared/services/adoreservice.service';
// import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dialogpopup',
  templateUrl: './dialogpopup.component.html',
  styleUrls: ['./dialogpopup.component.scss']
})
export class DialogpopupComponent implements OnInit {

  empid:any;
  grosssalary:any;
  Salarybrekup:any;
  empid1:any;
  employeeId:any;
  count:any;
 
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private service: AdoreserviceService,public dialog: MatDialog,private router: Router,private route: ActivatedRoute) {
  
    console.log("data"+data.id);

     this.empid = localStorage.getItem("empid"); 
  
  
        service.GetEmployeeSalaryBreakDown(data.id).subscribe((data: any) => {
          
      this.Salarybrekup = data;
      // console.log( JSON.stringify (this.Salarybrekup) + "Salarybrekup");
      // console.log( this.Salarybrekup);
    });
   
    service.GetEmployeeSalaryBreakDown(data.id).subscribe((data: any) => {

      if (data == "") {

        this.count = 0;
      }
      else if (data == null && data[0] == null) {

        this.count = 0;
      }
      else {
        this.count = data[0].length;
      }

     
    })
   }


   clear(){
    this.dialog.ngOnDestroy();
   }
  ngOnInit() {
  }


}
