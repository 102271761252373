import { Component, OnInit, EventEmitter, Inject } from '@angular/core';
// import { MatDialog } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Emppramotion } from '../../../../app/classfiles/emppramotion';
import { ToastrService } from 'ngx-toastr';
import { AdoreserviceService } from 'src/app/shared/services/adoreservice.service';

@Component({
  selector: 'app-salaryincrementpopup',
  templateUrl: './salaryincrementpopup.component.html',
  styleUrls: ['./salaryincrementpopup.component.scss']
})
export class SalaryincrementpopupComponent implements OnInit {
  incrementForm: FormGroup;
  grosssalary:any;
  incement:any;
  totgross:any;
  newctc:any;
  Salary:any;
  empid:any;
  newSalary:any;
  Salarytable:any;
  oldctc:any;
  finyear:any;
  submited = new EventEmitter<string>();
  count:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: AdoreserviceService,
  public dialog: MatDialog, private router: Router, private route: ActivatedRoute, 
  private fb: FormBuilder, private toastr: ToastrService) { 
  this.empid = data.id;
  this.incrementForm = this.fb.group({

    grosssalary: [''],
    incrementsalary:  [''],
    totsalary:  [''],
    ctc:  [''],

  });
 service.GetEmployeeSalaryBreakDown(data.id).subscribe((data1: any) => {
  this.Salary = data1;
  // console.log( this.Salary);
  this.grosssalary = data1[0].grosssalary;
  // console.log( this.grosssalary);
  this.totgross = data1[0].grosssalary;
  this.newctc = data1[0].ctc;
  this.oldctc = data1[0].ctc;
  // console.log(this.newctc);
});
service.GetEmployeePromotion(data.id).subscribe((data: any) => {
  this.Salarytable = data;
  // console.log( this.Salarytable);

});
this.service.GetEmployeePromotion(data.id).subscribe((data: any) => {

  if (data == "") {

    this.count = 0;
  }
  else if (data == null && data[0] == null) {

    this.count = 0;
  }
  else {
    this.count = data[0].tRecordCount;
  }

  // console.log(this.count);
});
this.service.GetName("FinanacialYear","yearname","status=0").subscribe((data: any) => {
  this.finyear = data;

});
  }

  ngOnInit() {
    
  }
  clear(){
     this.dialog.ngOnDestroy();
    }

    changeincrement(event1:any) {

      this.incement = event1;
      // console.log(this.incement);
      this.totgross = +this.grosssalary + +this.incement;

      this.service.GetNewGrossSalaryDetail(this.empid, this.totgross).subscribe((data2: any) => {
          
        this.newSalary = data2;
        // console.log("new salary"+ this.newSalary);
      
        this.newctc = data2.ctc;
        // console.log( this.newctc);
      });
        }
     

  



  submit(){

    const data: Emppramotion = new Emppramotion();
    data.empid = this.empid;
    data.finyear = this.finyear;
    data.oldctc = this.oldctc;
    data.oldgross = this.grosssalary;
    data.incregross = this.incement;
    data.newgross = this.totgross;
    data.newctc = this.newctc;
    console.log(data);
    // this.incrementForm.reset();
    this.service.InsertEmployeePromotion(data).subscribe((res) => {

      this.submited.emit(res.toString());
      
      console.log(res.toString());
     
      this.toastr.success("Increment Updated Successfully")
     
      this.service.GetEmployeePromotion(this.empid).subscribe((data: any) => {this.Salarytable = data;});
     
      
    },
      error => {
        console.log(error);
      }
    );
  }
}
