import { Component, OnInit, Inject } from '@angular/core';
// import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdoreserviceService } from 'src/app/shared/services/adoreservice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-salarybrekuppopup',
  templateUrl: './salarybrekuppopup.component.html',
  styleUrls: ['./salarybrekuppopup.component.scss']
})
export class SalarybrekuppopupComponent implements OnInit {
  empid;
  emptype;
  month;
  year;
  salaryslip:any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: AdoreserviceService, public dialog: MatDialog, private router: Router,
    private route: ActivatedRoute) {


  this.empid = data.empid;
  this.emptype = data.emptype;
  this.month = data.month;
  this.year = data.year;
  // console.log(this.empid, this.emptype , this.month, this.year);

  service.GetSalarySlipPrintData('where Salarymaster.salmonth=\'' + this.month + '\' and Salarymaster.salyear=\'' + this.year + '\' and Employee.empid=\'' + this.empid + '\'').subscribe((data: any) => {
    this.salaryslip = data;
    // console.log('salaryslip' + this.salaryslip);
  });
     }

  ngOnInit() {
  }


}
