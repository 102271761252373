import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/classfiles/user';
import { environment } from 'src/environments/environment';
import { City } from 'src/app/classfiles/city';
import { Distirct } from 'src/app/classfiles/distirct';

@Injectable({
  providedIn: 'root',
})
export class AdoreserviceService {
  constructor(private http: HttpClient) {}
  Login(user: User) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'Login/',
      user,
      { headers: headers }
    );
  }
  getMenus(
    userId: string,
    pgid: string,
    roleid: string,
    MenuId: any,
    typeid: any
  ) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'Menu/' +
        userId +
        '/' +
        pgid +
        '/' +
        roleid +
        '/' +
        MenuId +
        '/' +
        typeid
    );
  }

  InsertEmployee(employee: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'InsertEmployee',
      employee
    );
  }

  GetName(table: string, col: string, where: any) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetName?table=' +
        table +
        '&col=' +
        btoa(col) +
        '&whr=' +
        btoa(where)
    );
  }
  GetEmployeeList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeList?whr=' +
        btoa(where)
    );
  }
  GetEmployeeDetails(empid: string): any {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'GetEmployeeDetails/' + empid
    );
  }

  GetEmployeeDocument(empid: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeDocument/' +
        empid
    );
  }

  GetProfileImage(empid: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeProfiePic/' +
        empid
    );
  }

  GetEmployeeProgram(empid: string) {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'GetEmployeeProgram/' + empid
    );
  }

  GetEmployeeQualification(empid: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeQualification/' +
        empid
    );
  }

  GetEmployeeReference(empid: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeReference/' +
        empid
    );
  }

  GetHideButton(
    roleid: string,
    pgid: string,
    pagename: string,
    buttontype: string
  ) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetHideButton/' +
        roleid +
        '/' +
        pgid +
        '/' +
        pagename +
        '/' +
        buttontype
    );
  }

  GetDistrictList() {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'DistrictList'
    );
  }

  GetCityList() {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'CityList'
    );
  }

  GetOfficeList(where: string) {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'GetOfficeList?whr=' + where
    );
  }

  GetProgramlist(where: string) {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'GetProgramlist?whr=' + where
    );
  }

  GetStatewiseDistrictList(stname: string) {
    return this.http.get<Distirct[]>(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'StatewiseDistrictList/' +
        stname
    );
  }

  GetDistrictwiseCityList(dtname: string) {
    return this.http.get<City[]>(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'DistrictwiseCityList/' +
        dtname
    );
  }

  InsertUserActivity(user: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'InsertUserActivity',
      user
    );
  }

  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  postFile(empid: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'UploadEMPPhoto/' + empid,
      formData
    );
  }

  GetStateList() {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'StateList'
    );
  }

  UpdateEmployee(empid: string, employee: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'UpdateEmployee/' + empid,
      employee
    );
  }

  EmployeeNameList() {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'EmployeeNameList'
    );
  }

  GetReportstoEmp(desid: string) {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'GetReportstoEmp/' + desid
    );
  }

  InsertEmployeeProgram(Empprogram: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'InsertEmployeeProgram',
      Empprogram
    );
  }

  RemoveEmployeeProgram(efid: number) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'RemoveEmployeeProgram/' +
        efid
    );
  }

  InsertEmployeeQualification(EmpQ: any) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'InsertEmployeeQualification',
      EmpQ
    );
  }

  RemoveEmployeeQualification(quliid: number) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'RemoveEmployeeQualification/' +
        quliid
    );
  }

  InsertEmployeeReference(EmpR: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'InsertEmployeeReference',
      EmpR
    );
  }

  RemoveEmployeeReference(refid: number) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'RemoveEmployeeReference/' +
        refid
    );
  }

  InsertEmployeeDocument(empid: string, year: any, data: any) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'InsertEmployeeDocument/' +
        empid +
        '/' +
        year,
      data
    );
  }

  RemoveEmployeeDocument(financialyear: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'RemoveEmployeeDocument/' +
        financialyear
    );
  }

  FillDropDown(
    tablename: string,
    column1: string,
    column2: string,
    where: string
  ) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'FillDropDown?tablename=' +
        tablename +
        '&column1=' +
        btoa(column1) +
        '&column2=' +
        btoa(column2) +
        '&whr=' +
        btoa(where)
    );
  }

  UploadEmployeeDocument(
    empid: string,
    fineyear: string,
    documenttype: string,
    fileToUpload: File
  ) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'UploadEmployeeDocument/' +
        empid +
        '/' +
        fineyear +
        '/' +
        documenttype,
      formData
    );
  }

  GetEmployeeReport(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetRptEmployee?whr=' +
        btoa(where)
    );
  }

  GetEmployeeSalaryBreakDown(empid: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeSalaryBreakDown/' +
        empid
    );
  }
  DepartmentwiseEmpAttendanceList(whr: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'DepartmentwiseEmpAttendanceList?whr=' +
        btoa(whr)
    );
  }

  EmployeeAttDetailsList(month: string, year: string, where: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'EmployeeAttDetailsList/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }

  EmployeeAttDetailsData(month: string, year: string, where: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'EmployeeAttDetailsData/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }

  GetEmpAttAllSumPieGraph(month: string, year: string, where: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpAttAllSumPieGraph/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }

  GetEmpAttAllSumlineGraph(month: string, year: string, where: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpAttAllSumlineGraph/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }

  GetEmpAttAllSumbarGraph(empcode: string, month: string, year: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpAttAllSumbarGraph/' +
        empcode +
        '/' +
        month +
        '/' +
        year
    );
  }

  GetEmpAttAllSumbarGraphback(
    empcode: string,
    month: string,
    year: string
  ): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpAttAllSumbarGraphback/' +
        empcode +
        '/' +
        month +
        '/' +
        year
    );
  }

  GetEmployeeAttGraph(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpAttAllSumGraphData?whr=' +
        btoa(where)
    );
  }

  LeaveCountList() {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'LeaveCountList'
    );
  }

  GetEmployeeLeaveData(i: string) {
    return this.http.get(
      environment.apiUrl + 'JankariPortalAdore/' + 'GetEmployeeLeaveData/' + i
    );
  }

  AddEmployeeLeave(lcid: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'AddEmployeeLeave',
      lcid
    );
  }

  UpdateEMPAttendance(data: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'UpdateEMPAttendance/',
      data
    );
  }
  GetEmployeeLeaveList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeLeaveList?whr=' +
        btoa(where)
    );
  }

  GetSalaryList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalaryList?whr=' +
        btoa(where)
    );
  }

  CheckAttendace(month: string, year: string, where: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'CheckAttendace/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }

  CheckAttendaceList(month: string, year: string, where: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'CheckAttendaceList/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }

  AddEMPAttendance(month: number, year: number, empid: string, data: any) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'AddEMPAttendance/' +
        month +
        '/' +
        year +
        '/' +
        empid,
      data
    );
  }

  AddSalary(
    userid: string,
    pgid: string,
    depid: string,
    month: string,
    year: string,
    fineyear: string,
    data: any
  ) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'AddSalary/' +
        userid +
        '/' +
        pgid +
        '/' +
        depid +
        '/' +
        month +
        '/' +
        year +
        '/' +
        fineyear,
      data
    );
  }

  GetEmpSalary(
    month: string,
    year: string,
    finyear: string,
    where: string
  ): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpSalary/' +
        month +
        '/' +
        year +
        '/' +
        finyear +
        '?whr=' +
        btoa(where)
    );
  }

  GetRptSalary(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetRptSalary?whr=' +
        btoa(where)
    );
  }

  GetSalaryDetailsData(salid: string, where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalaryDetailsData/' +
        salid +
        '?whr=' +
        btoa(where)
    );
  }

  GetEmployeeAttendanceSummary(
    frommonth: string,
    tomonth: string,
    fromyear: string,
    toyear: string,
    where: string
  ) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeAttendanceSummary/' +
        frommonth +
        '/' +
        tomonth +
        '/' +
        fromyear +
        '/' +
        toyear +
        '?whr=' +
        btoa(where)
    );
  }
  GetEmployeeLeaveDetails(i: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeLeaveDetails/' +
        i
    );
  }
  ApproveEmployeeLeave(
    lcid: string,
    empid: string,
    escalateid: string,
    data: any
  ) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'ApproveEmployeeLeave/' +
        lcid +
        '/' +
        empid +
        '/' +
        escalateid,
      data
    );
  }
  GetSalAlertList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalAlertList?whr=' +
        btoa(where)
    );
  }
  GetEmployeeLoanList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeLoanList?whr=' +
        btoa(where)
    );
  }
  ApproveSalary(Salid: string, userid: any, data: any) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'ApproveSalary/' +
        Salid +
        '/' +
        userid,
      data
    );
  }
  GetEmpApprovalSalaryData(where: string, month: string, year: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmpApprovalSalaryData/' +
        month +
        '/' +
        year +
        '?whr=' +
        btoa(where)
    );
  }
  GetSalarySlipRequestList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalarySlipRequestList?whr=' +
        btoa(where)
    );
  }
  GetSalarySlipNewList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalarySlipNewList?whr=' +
        btoa(where)
    );
  }
  GetSalaryBrakupList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalaryBrakupList?whr=' +
        btoa(where)
    );
  }
  AddDownloadsalarySlip(
    empid: string,
    depid: string,
    pgid: string,
    year: string,
    finyear: string,
    data: any
  ) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'AddDownloadsalarySlip/' +
        empid +
        '/' +
        depid +
        '/' +
        pgid +
        '/' +
        year +
        '/' +
        finyear,
      data
    );
  }
  ApprovesalarySlip(empid: string, data: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'ApprovesalarySlip/' + empid,
      data
    );
  }
  AddsalarySlip(empid: string, month: number, year: number) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'AddsalarySlip/' +
        empid +
        '/' +
        month +
        '/' +
        year
    );
  }

  GetRptAPPEmployeeAttendance(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetRptAPPEmployeeAttendance?whr=' +
        btoa(where)
    );
  }
  GetEmployeeMonthlyAttendance(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeeMonthlyAttendance?whr=' +
        btoa(where)
    );
  }

  GetRptAPPEmployeeAttendanceNotMarked(date: string, where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetRptAPPEmployeeAttendanceNotMarked?date=' +
        date +
        '&whr=' +
        btoa(where)
    );
  }

  GetSalarySlipPrintData(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetSalarySlipPrintData?whr=' +
        btoa(where)
    );
  }
  GetNumbersToWords(inputNumber: number) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetNumbersToWords/' +
        inputNumber
    );
  }

  GetProfile(userid: string, pgid: string): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetProfile/' +
        userid +
        '/' +
        pgid
    );
  }
  UpdateOTPPassword(userid: string, otp: string, data: any) {
    return this.http.post(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'UpdateOTPPassword/' +
        userid +
        '/' +
        otp,
      data
    );
  }
  GetMyJankariEmpAttList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetMyJankariEmpAttList?whr=' +
        btoa(where)
    );
  }
  GetUserDailyTaskList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetUserDailyTaskList?whr=' +
        btoa(where)
    );
  }
  AddUserDailyTaskA(userid: string, task: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'AddUserDailyTask/' + userid,
      task
    );
  }
  GetUserDailyReportList(where: string) {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetUserDailyReportList?whr=' +
        btoa(where)
    );
  }
  GetEmployeePromotion(empid: number): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetEmployeePromotion/' +
        empid
    );
  }
  GetNewGrossSalaryDetail(empid: number, grosssalary: number): any {
    return this.http.get(
      environment.apiUrl +
        'JankariPortalAdore/' +
        'GetNewGrossSalaryDetail/' +
        empid +
        '/' +
        grosssalary
    );
  }
  InsertEmployeePromotion(data: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'InsertEmployeePromotion/',
      data
    );
  }

  ForgotPassword(username: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'ForgotPassword',
      username
    );
  }
  CheckOTP(data: any) {
    return this.http.post(
      environment.apiUrl + 'JankariPortalAdore/' + 'CheckOTP/',
      data
    );
  }

  CenterList(userid: string, role: string, pgid: string, where: string): any {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'CenterList/' +
        userid +
        '/' +
        role +
        '/' +
        pgid +
        '?whr=' +
        btoa(where)
    );
  }
  CenterListAD(userid: string, role: string, where: string): any {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'CenterListAD/' +
        userid +
        '/' +
        role +
        '?whr=' +
        btoa(where)
    );
  }
  FillDropDownUserwise(
    us: string,
    ur: string,
    pgid: string,
    whr: string,
    col1: string,
    col2: string
  ) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'FillDropDownUserwise?us=' +
        us +
        '&ur=' +
        ur +
        '&pgid=' +
        pgid +
        '&whr=' +
        btoa(whr) +
        '&col1=' +
        btoa(col1) +
        '&col2=' +
        btoa(col2)
    );
  }

  FillDropDownUserwiseAD(
    us: string,
    ur: string,
    whr: string,
    col1: string,
    col2: string
  ) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'FillDropDownUserwiseAD?us=' +
        us +
        '&ur=' +
        ur +
        '&whr=' +
        btoa(whr) +
        '&col1=' +
        btoa(col1) +
        '&col2=' +
        btoa(col2)
    );
  }

  FillEmpByDepDes(where: string) {
    return this.http.get(
      environment.jankariUrl +
        'JankariPortal/' +
        'FillEmpByDepDes?whr=' +
        btoa(where)
    );
  }

  GetCenterotherInfoList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetCenterotherInfoList/' + centid
    );
  }

  CenterTypeList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'CenterTypeList/' + centid
    );
  }

  GetCenterPartnerList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetCenterPartnerList/' + centid
    );
  }
  GetSancharakList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetSancharakList/' + centid
    );
  }
  GetAgreementList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetAgreementList/' + centid
    );
  }
  GetGSTList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetGSTList/' + centid
    );
  }

  // GetCenterotherInfoList(centid: string) {
  //   return this.http.get(environment.jankariUrl + 'Center/' + 'GetCenterotherInfoList/' + centid);
  // }

  GetCenterComputerList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetCenterComputerList/' + centid
    );
  }

  UpdateCenter(
    userid: string,
    role: string,
    pgid: string,
    centid: string,
    data: any
  ) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'UpdateCenter/' +
        userid +
        '/' +
        role +
        '/' +
        pgid +
        '/' +
        centid,
      data
    );
  }

  AddCenterotherInfo(centid: string, userid: string, data: any) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'AddCenterotherInfo/' +
        centid +
        '/' +
        userid +
        '/',
      data
    );
  }

  AddCenterType(centid: string, userid: string, data: any) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'AddCenterType/' +
        centid +
        '/' +
        userid +
        '/',
      data
    );
  }

  AddCenterInfra(centid: string, userid: string, data: any) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'AddCenterInfra/' +
        centid +
        '/' +
        userid +
        '/',
      data
    );
  }

  CenterInfraList(centid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'CenterInfraList/' + centid
    );
  }

  UploadAgreement(
    centid: string,
    userid: string,
    stdt: Date,
    endt: Date,
    fileToUpload: File
  ) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'UploadAgreement/' +
        centid +
        '/' +
        userid +
        '/' +
        stdt +
        '/' +
        endt,
      formData
    );
  }

  AddCenterGST(centid: string, userid: string, data: any) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'AddCenterGST/' +
        centid +
        '/' +
        userid +
        '/',
      data
    );
  }

  UploadCenterGST(
    centid: string,
    userid: string,
    gstapp: string,
    academicyear: string,
    fileToUpload: File
  ) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'UploadCenterGST/' +
        centid +
        '/' +
        userid +
        '/' +
        gstapp +
        '/' +
        academicyear,
      formData
    );
  }

  AddCenterComputer(centid: string, data: any) {
    return this.http.post(
      environment.jankariUrl + 'Center/' + 'AddCenterComputer/' + centid + '/',
      data
    );
  }

  RemoveCenterComputer(hardwareid: number) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveCenterComputer/' + hardwareid
    );
  }
  AddCenterPartner(centid: string, userid: string, data: any) {
    return this.http.post(
      environment.jankariUrl +
        'Center/' +
        'AddCenterPartner/' +
        centid +
        '/' +
        userid,
      data
    );
  }
  RemoveCenterGST(gid: number) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveCenterGST/' + gid
    );
  }

  RemoveCenterPartner(cpid: number) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveCenterPartner/' + cpid
    );
  }

  RemoveAgreement(agid: number) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveAgreement/' + agid
    );
  }

  RemoveCenterType(ctid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveCenterType/' + ctid
    );
  }

  RemoveCenterInfra(infraid: string) {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'RemoveCenterInfra/' + infraid
    );
  }

  GetCenterDetails(centid: string): any {
    return this.http.get(
      environment.jankariUrl + 'Center/' + 'GetCenterDetails/' + centid
    );
  }

  GetRptHardwareCallAD(
    userid: string,
    role: string,
    pgid: string,
    where: string
  ) {
    return this.http.get(
      environment.jankariUrl +
        'Center/' +
        'GetRptHardwareCallAD/' +
        userid +
        '/' +
        role +
        '/' +
        pgid +
        '?whr=' +
        btoa(where)
    );
  }
}
