import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Forgetpassword } from '../../../../app/classfiles/forgetpassword';
import { AdoreserviceService } from 'src/app/shared/services/adoreservice.service';
import { OtppopupComponent } from '../otppopup/otppopup.component';

@Component({
  selector: 'app-forgotpasswordpopup',
  templateUrl: './forgotpasswordpopup.component.html',
  styleUrls: ['./forgotpasswordpopup.component.scss'],
})
export class ForgotpasswordpopupComponent implements OnInit {
  otpform: FormGroup;
  otpdata: string = '';
  submited = new EventEmitter<string>();

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private service: AdoreserviceService,
    private toastr: ToastrService
  ) {
    this.otpform = this.fb.group({
      mobile: ['', Validators.required],
    });
  }

  ngOnInit() {}
  get f() {
    return this.otpform.controls;
  }
  Submit() {
    const data: Forgetpassword = new Forgetpassword();

    data.username = this.otpdata;
    // console.log(data)
    this.service.ForgotPassword(data).subscribe(
      (res) => {
        console.log(res);
        this.submited.emit(res.toString());
        console.log(res.toString());
        if (res.toString() == 'Success') {
          localStorage.setItem('otpdata', this.otpdata);
          this.toastr.success('OTP send to your Mobile or Email');
          this.dialog.closeAll();
          this.dialog.open(OtppopupComponent, { disableClose: true });
        } else {
          this.toastr.error('Enter Proper Information');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  openDialog() {}
}
