import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    showLoadingIndicator = true;
    constructor(private translate: TranslateService,private router: Router , private _router: Router) {
        translate.setDefaultLang('en');
        this._router.events.subscribe((routerEvent: Event) => {
            if (routerEvent instanceof NavigationStart) {
            this.showLoadingIndicator = true;
            }
            if (routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationError ||
            routerEvent instanceof NavigationCancel) {
            this.showLoadingIndicator = false;
            }
            });
    }

    ngOnInit() {
      
    }


}
